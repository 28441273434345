import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const SELECTOR = '.js-menu-link';
let menu: LitPopupElement | null;

const onClick = () => {
    menu?.close();
};

function init() {
    const menuLinks = Array.from(document.querySelectorAll(SELECTOR));
    menu = document.querySelector<LitPopupElement>('[data-lit-popup="menu"]');

    menuLinks.forEach((link) => {
        link.addEventListener('click', onClick);
    });
}

function destroy() {
    Array.from(document.querySelectorAll<HTMLElement>(SELECTOR)).forEach(
        (el) => {
            el.removeEventListener('click', onClick);
        },
    );
}

const _module = { init, destroy };

export default _module;
