function addFocus(this: HTMLElement) {
    this.classList.add('is-not-empty');
}

function removeFocus(this: HTMLElement) {
    this.classList.remove('is-not-empty');
}

function changeInput(this: HTMLInputElement) {
    if (this.value.length >= 1) {
        addFocus.call(this);
    } else {
        removeFocus.call(this);
    }
}

function handleFocus(this: HTMLInputElement | HTMLSelectElement) {
    if (this.value.length === 0) {
        removeFocus.call(this);
    }
}

function init() {
    const inputsOfForm =
        document.querySelectorAll<HTMLInputElement>('.js-input');

    // если инпут заполнен, лэйбел находится наверху
    inputsOfForm.forEach((input) => {
        if (input.value) {
            addFocus.call(input);
        }

        setTimeout(() => {
            if (input.value) {
                addFocus.call(input);
            }
        }, 0);

        input.addEventListener('change', changeInput);
        input.addEventListener('focus', addFocus);
        input.addEventListener('blur', handleFocus);
    });
}

function destroy() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-input')).forEach(
        (el) => {
            if (el) {
                el.removeEventListener('change', changeInput);
                el.removeEventListener('focus', addFocus);
                el.removeEventListener('blur', handleFocus);
            }
        },
    );
}

const _module = { init, destroy };

export default _module;
