import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import * as Cookies from 'js-cookie';

import _styles from './CookiesAgreement.ce.scss';

export interface CookiesAgreement {
    revealTimeout: number;
    _isVisible: boolean;
    _timer: NodeJS.Timeout;
}

let bgColor = '#76d9bd';
const bodyElement = document.body;

if(bodyElement.classList.contains('contacts')) {
    bgColor = '#cae3e5'
}

/**
 * @attr {Number} reveal-timeout - Через сколько секунд сообщение покажется пользователю
 *
 * @slot - Контент в формате HTML
 */
export class CookiesAgreement extends LitElement {
    constructor() {
        super();
        this._acceptCookiesUsage = this._acceptCookiesUsage.bind(this);

        this.revealTimeout = this.getAttribute('reveal-timeout')
            ? parseFloat(this.getAttribute('reveal-timeout') as string)
            : 5000;
        this._isVisible = false;
    }

    static get properties() {
        return {
            revealTimeout: {
                type: Number,
                attribute: 'reveal-timeout',
            },
            _isVisible: {
                type: Boolean,
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this._timer = setTimeout(() => {
            this._isVisible = true;
        }, this.revealTimeout);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._timer);
    }

    close() {
        this._isVisible = false;
    }

    protected _acceptCookiesUsage() {
        Cookies.set('COOKIES_USAGE_ACCEPTED', 'true', {
            expires: 365, // days
        });
        this.close();
    }

    render() {
        return html`
            <div class="${classMap({ banner: true, 'banner--visible': this._isVisible })}" style="background-color : ${bgColor}">
                <div class="banner__head">
                    <div class="banner__tag banner__tag--icon">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 9.00024C1 12.3139 3.68629 15.0002 7 15.0002C9.57206 15.0002 11.7661 13.3818 12.6194 11.1078C12.8328 10.5392 12.3585 9.99419 11.8018 9.75138C10.7413 9.28884 10 8.23111 10 7.00024C10 6.5553 9.44494 6.00024 9 6.00024C7.76913 6.00024 6.7114 5.25896 6.24886 4.19849C6.00605 3.64177 5.46106 3.16747 4.89241 3.38084C2.6184 4.23412 1 6.42818 1 9.00024Z" stroke="#282828" stroke-width="1.5"/>
                            <circle cx="5" cy="8" r="1" stroke="#282828" stroke-width="1.5"/>
                            <circle cx="8" cy="11" r="1" stroke="#282828" stroke-width="1.5"/>
                            <circle cx="11" cy="2" r="1" stroke="#282828" stroke-width="1.5"/>
                        </svg>
                    </div>
                    <div class="banner__tag">Cookies</div>
                </div>
                <div class="banner__text">
                    <slot></slot>
                </div>
                <button
                    class="banner__close"
                    @click="${this._acceptCookiesUsage}"
                    aria-label="Принять и закрыть"
                    title="Принять и закрыть"
                >
                    Принять
                </button>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-cookies-agreement': CookiesAgreement;
    }
}

if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
    customElements.define('app-cookies-agreement', CookiesAgreement);
}
