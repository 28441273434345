function scrollUp() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

function init(container: HTMLElement | Document = document) {
    const btnsUp = Array.from(container.querySelectorAll('.js-scroll-up'));
    btnsUp.forEach((btn) => {
        btn.addEventListener('click', scrollUp);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const btnsUp = Array.from(container.querySelectorAll('.js-scroll-up'));
    btnsUp.forEach((btn) => {
        btn.removeEventListener('click', scrollUp);
    });
}

const _module = { init, destroy };

export default _module;
