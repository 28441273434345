import './webpack-imports';
import './sentry';
import './custom-elements';
import barba from '@barba/core';
import { applyPolyfills } from './polyfills';
import BaseTransition from './transitions/base';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import { createInitialBaseTimeline } from './animations/initial-base';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import menuLinks from './inits/menu-links';

document.documentElement.classList.add('js-ready');
if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

applyPolyfills().then(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const initApp = () => {
        initBaseView();
        const animation = createInitialBaseTimeline();
        menuLinks.init();

        barba.init({
            transitions: [BaseTransition],
            views: [
                lazyView('index-page', () =>
                    import(
                        /* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index'
                    ).then((m) => m.default),
                ),
                lazyView('detail-page', () =>
                    import(
                        /* webpackChunkName: "detail-page", webpackPrefetch: true */ './views/detail'
                    ).then((m) => m.default),
                ),
                lazyView('history-page', () =>
                    import(
                        /* webpackChunkName: "history-page", webpackPrefetch: true */ './views/history'
                    ).then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });

        requestAnimationFrame(() => {
            animation.play();
        });
    };
    preloader
        .loadAssets()
        .then(() => timeout(1000))
        .then(() => preloader.leave())
        .then(() => initApp());
});
