import { lockScroll } from '../components/lockScroll';

const SELECTOR = '.js-close-popup';

const onClick = () => {
    setTimeout(() => {
        lockScroll();
    }, 305);
};

function init() {
    const popupLinks = Array.from(document.querySelectorAll(SELECTOR));

    popupLinks.forEach((link) => {
        link.addEventListener('click', onClick);
    });
}

function destroy() {
    Array.from(document.querySelectorAll<HTMLElement>(SELECTOR)).forEach(
        (el) => {
            el.removeEventListener('click', onClick);
        },
    );
}

const _module = { init, destroy };

export default _module;
