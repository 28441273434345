import gsap from 'gsap';

export function createInitialBaseTimeline() {
    const tl = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.6,
            ease: 'cubic-bezier(.25,.46,.45,.94)',
            delay: 0.2,
        },
        onComplete: () => {
            document.documentElement.classList.add(
                'initial-animation-complete',
            );
        },
    });

    tl.fromTo(
        '.a-detail',
        {
            opacity: 0,
        },
        {
            opacity: 1,
        },
    )
        .fromTo(
            '.a-header-item',
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
                stagger: 0.2,
            },
            '-=1',
        )
        .fromTo(
            '.a-header-nav',
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
            },
            '-=0.6',
        )
        .fromTo(
            '.a-second-block',
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
                stagger: 0.3,
            },
            '-=0.6',
        )
        .fromTo(
            '.a-second-block-item',
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
                stagger: 0.3,
            },
            '-=1.2',
        );

    return tl;
}
