import debounce from 'lodash.debounce';

const beforeResize = {
    width: window.innerWidth,
};

export default () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vh-static', `${vh}px`);

    const calculateVhOnResize = debounce(() => {
        if (!window.matchMedia('(max-width: 1024px)').matches) {
            return;
        }

        const newVh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);

        // проверяем что был ресайз не из-за шапки (сафари)
        if (Math.abs(beforeResize.width - window.innerWidth) > 0) {
            beforeResize.width = window.innerWidth;

            if (newVh > vh) {
                document.documentElement.style.setProperty(
                    '--vh-static',
                    `${newVh}px`,
                );
            }
        }
    }, 40);

    const calculateVhOnOrientationChange = debounce(() => {
        if (!window.matchMedia('(max-width: 1024px)').matches) {
            return;
        }

        const newVh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
        document.documentElement.style.setProperty('--vh-static', `${newVh}px`);
    }, 50);

    window.addEventListener('resize', calculateVhOnResize);
    window.addEventListener(
        'orientationchange',
        calculateVhOnOrientationChange,
    );

    return () => {
        window.removeEventListener('resize', calculateVhOnResize);
        window.removeEventListener(
            'orientationchange',
            calculateVhOnOrientationChange,
        );
    };
};
