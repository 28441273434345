import barba from '@barba/core';
import scrollUp from '../inits/scroll-up';
import { lazyload } from '../inits/lazy-load';
import splittedText from '../animations/splitted-text';
import revealAnimations from '../inits/reveal';
import scrollTo from '../inits/scrollto';
import headerTheme from '../inits/header-theme';
import inputs from '../inits/inputs';
import ajaxForms from '../inits/ajax-forms';
import inputMasks from '../inits/masks';
import footerEffect from '../inits/footer';
import menuCloseTrigger from '../inits/menu-close-trigger';
import vhMobile from '../../modules/vh-mobile-fix';
import { unlockScroll } from '../components/lockScroll';

export function initBaseView() {
    barba.hooks.beforeEnter((data) => {
        ajaxForms.init();
        inputMasks.init();
        inputs.init();
        footerEffect.init(data?.next.container);
        menuCloseTrigger.init();
        vhMobile();
    });

    barba.hooks.enter((data) => {
        if (
            data &&
            data.current &&
            data.next &&
            data.current.namespace &&
            data.next.namespace
        ) {
            document.body.classList.replace(
                data.current.namespace,
                data.next.namespace,
            );
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        revealAnimations.init();
        splittedText.init();
        scrollTo.init();
        headerTheme.init(data?.next.container);

        const footer = document.querySelector<HTMLElement>('.js-footer');

        if (footer) {
            scrollUp.init(footer);
        }

        setTimeout(() => {
            scrollTo.init(data?.next.container);
        }, 1);
    });

    barba.hooks.beforeLeave((data) => {
        ajaxForms.destroy();
        inputMasks.destroy();
        revealAnimations.destroy(data?.current.container);
        scrollTo.destroy();
        inputs.destroy();
        menuCloseTrigger.destroy();

        const footer = document.querySelector<HTMLElement>('.js-footer');

        if (footer) {
            scrollUp.destroy(footer);
        }
    });

    barba.hooks.afterLeave((data) => {
        headerTheme.destroy(data?.current.container);
        footerEffect.destroy(data?.current.container);
        unlockScroll();
    });
}
