import gsap from 'gsap';
import barba from '@barba/core';
import { getOffsetTop } from '../utils/dom';

const SELECTOR = '[data-scrollto]';

let elements: HTMLElement[] = [];

export async function scrollTo(el: Element) {
    const y = getOffsetTop(el);
    gsap.to(window, {
        duration: 1,
        scrollTo: y,
        ease: 'cubic-bezier(.25,.46,.45,.94)',
    });
}

async function onClick(this: HTMLElement, event: Event) {
    event.preventDefault();
    const selector = this.dataset.scrollto;

    if (!selector) {
        throw new Error(`[scrollto] ${SELECTOR} is missing on the element.`);
    }

    const elementScrollTo = document.querySelector(selector);

    if (elementScrollTo) {
        scrollTo(elementScrollTo);
    } else if (this instanceof HTMLAnchorElement && this.href) {
        barba.go(this.href);
    }
}

function init(container: HTMLElement | Document = document) {
    elements = Array.from(document.querySelectorAll<HTMLElement>(SELECTOR));
    elements.forEach((el) => {
        el.addEventListener('click', onClick);
    });

    if (window.location.hash) {
        const elementScrollTo = container.querySelector(window.location.hash);
        const headerNav = container.querySelector('.js-header-nav');
        const header = document.querySelector('.js-header');

        if (elementScrollTo) {
            const y = getOffsetTop(elementScrollTo);
            window.scrollTo({ top: y, behavior: 'auto' });
            setTimeout(() => {
                const linkWithoutHash = window.location.href.split('#')[0];
                history.pushState(null, '', linkWithoutHash);
                header?.classList.remove('header--light');
                headerNav?.classList.remove('page-top--light');
            }, 1000);
        }
    }
}

function destroy() {
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });

    elements = [];
}

const _module = { init, destroy };

export default _module;
