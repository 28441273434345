import gsap from 'gsap';

const timelinesMap = new WeakMap<Element, gsap.core.Tween>();
let footer: HTMLElement | null;
let pageInner: HTMLElement | null;

function onResize() {
    const footerHeight = footer?.offsetHeight;
    if (pageInner && footerHeight) {
        pageInner.style.marginBottom = `${footerHeight}px`;
    }
}

function init(container: Element | Document = document) {
    footer = document.querySelector('.js-footer');
    pageInner = container.querySelector('.js-page-inner');
    const pageNav = container.querySelector<HTMLElement>('.js-page-left-nav');

    if (footer && pageInner) {
        const footerHeight = footer?.offsetHeight;
        pageInner.style.marginBottom = `${footerHeight}px`;
        setTimeout(() => {
            if (pageInner) {
                const tween = gsap.to(footer, {
                    ease: 'none',
                    scrollTrigger: {
                        trigger: pageInner,
                        start: 'bottom bottom',
                        end: 'bottom+=100 bottom',
                        scrub: 0.1,
                        onEnter: () => {
                            if (pageInner) {
                                const scaleKoef =
                                    (pageInner.offsetWidth - 20) /
                                    pageInner.offsetWidth;
                                pageInner.style.setProperty(
                                    '--scaleKoef',
                                    `${scaleKoef}`,
                                );
                                pageInner?.classList.add('is-scale');
                            }
                            if (pageNav) pageNav.style.position = 'absolute';
                        },
                        onLeaveBack: () => {
                            pageInner?.classList.remove('is-scale');
                            if (pageNav) pageNav.style.position = 'fixed';
                        },
                    },
                });

                timelinesMap.set(pageInner, tween);
            }
        }, 1000);
    }

    window.addEventListener('resize', onResize);
}

function destroy(container: Element | Document = document) {
    const pageInner = container.querySelector<HTMLElement>('.js-page-inner');
    if (pageInner) {
        const tween = timelinesMap.get(pageInner);

        if (tween) {
            tween.kill();
            timelinesMap.delete(pageInner);
        }
    }
}

const _module = { init, destroy };

export default _module;
