import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';
import { createInitialBaseTimeline } from '../animations/initial-base';
const header = document.querySelector('.js-header');

export default {
    leave({ current, trigger }): Promise<void> {
        return new Promise((resolve) => {
            const triggerEl =
                typeof trigger !== 'string' ? (trigger as HTMLElement) : null;

            if (
                current.namespace == 'history-page' ||
                (triggerEl &&
                    triggerEl.classList.contains('js-history-trigger'))
            ) {
                const pageInner = current.container as HTMLElement;
                const fullPopup =
                    current.container.querySelector<HTMLElement>(
                        '.js-full-popup',
                    );
                if (pageInner && pageInner.classList.contains('js-page')) {
                    pageInner?.classList.add('is-scale');
                    if (fullPopup) fullPopup.classList.add('is-open');
                }
                setTimeout(resolve, 600);
            } else {
                const sections = Array.from(
                    current.container.querySelectorAll(
                        'section, .editor, .js-header-nav, .js-section',
                    ),
                );
                gsap.to(sections, {
                    duration: 0.4,
                    y: -30,
                    opacity: 0,
                    ease: 'cubic-bezier(.25,.46,.45,.94)',
                    onComplete: () => {
                        setTimeout(resolve, 100);
                    },
                });
                gsap.to(header, {
                    duration: 0.4,
                    y: -30,
                    opacity: 0,
                    ease: 'cubic-bezier(.25,.46,.45,.94)',
                });
            }
        });
    },

    enter(): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            gsap.set(header, {
                y: 0,
                opacity: 1,
            });
            resolve();
        });
    },

    afterEnter() {
        const animation = createInitialBaseTimeline();
        requestAnimationFrame(() => {
            animation.play();
        });
    },
} as ITransitionPage;
