import gsap from 'gsap';
export const preloaderAnimationDuration = 0.7; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    let loaded = false;

    const state = {
        completed: false,
    };

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));
            if (!loaded) {
                loaded = true;
            }
            const tl = gsap.timeline();

            tl.to(
                preloader,
                {
                    duration: 0.5,
                    opacity: 0,
                    onComplete: () => {
                        preloader?.remove();
                        state.completed = true;
                    },
                },
                0.5,
            ).add(resolve, 0.65);
        });
    }

    function loadAsset(
        asset: HTMLImageElement | HTMLVideoElement,
    ): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), {
                        once: true,
                    });
                }
            }
        });
    }

    async function loadAssetsFromElement(
        element: Element | Document = document,
    ) {
        const images = Array.from(
            element.querySelectorAll<HTMLImageElement>(
                'img:not(.lazy):not([loading="lazy"])',
            ),
        );
        const videos = Array.from(
            element.querySelectorAll<HTMLImageElement>(
                'video:not(.lazy):not([loading="lazy"])',
            ),
        );
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [
            ...images,
            ...videos,
        ];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
