import { CountUp } from 'countup.js';
const selector = '[data-reveal], [data-reveal-fade], .editor > *:not(b):not(i)';
const map = new WeakMap();
let rootMargin = window.matchMedia('(min-width: 1441px)').matches
    ? '0px -80px 0px 0px'
    : '0px -40px 0px 0px';

const counterOpetions = {
    separator: '',
    duration: 0.6,
    startVal: (Math.random() + 1) * 2000 + 3000,
    useEasing: true,
};

function onResize() {
    rootMargin = window.matchMedia('(min-width: 1441px)').matches
        ? '0px -80px 0px 0px'
        : '0px -40px 0px 0px';
}

function init(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLElement>(selector),
    );
    elements.forEach((el) => {
        const rootMarginEl = el.dataset.rootMargin || rootMargin;
        const observer = new IntersectionObserver(
            (entries, obs) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        obs.unobserve(entry.target);
                        entry.target.classList.add('is-revealed');

                        if (
                            entry.target.classList.contains(
                                'js-counter-animate',
                            )
                        ) {
                            const target = entry.target as HTMLElement;
                            const countUp = new CountUp(
                                target,
                                Number(target.textContent),
                                counterOpetions,
                            );
                            if (!countUp.error) {
                                countUp.start();
                            } else {
                                console.error(countUp.error);
                            }
                        }
                    }
                });
            },
            { rootMargin: rootMarginEl },
        );

        observer.observe(el);
        map.set(el, observer);
    });
    window.addEventListener('resize', onResize);
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLElement>(selector),
    );
    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
            map.delete(el);
        }
    });
    window.removeEventListener('resize', onResize);
}

const _module = { init, destroy };

export default _module;
