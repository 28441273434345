import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { executeOnIntersection } from '../utils/execute-on-intersection';
const selector = '.js-reveal-mask';

function splittedText(el: HTMLElement, speedKoef = 0.8) {
    el.classList.add('splittable');

    const splittedParent = new SplitText(el, {
        type: 'lines',
        linesClass: 'title-line-parent',
    });

    const splitted = new SplitText(
        el.getElementsByClassName('title-line-parent'),
        {
            type: 'lines',
        },
    );

    const tl = gsap.timeline({
        defaults: {
            duration: 1 * speedKoef,
            ease: 'cubic-bezier(.25,.46,.45,.94)',
        },
        paused: true,
        onComplete: () => {
            requestAnimationFrame(() => {
                if (!el.classList.contains('is-not-revert')) {
                    splittedParent.revert();
                    splitted.revert();
                }
            });
        },
    });

    tl.fromTo(
        splittedParent.lines,
        {
            rotate: -2,
            yPercent: 60,
        },
        {
            rotate: 0,
            yPercent: 0,
            duration: 1 * speedKoef,
            stagger: 0.1,
        },
    ).fromTo(
        splitted.lines,
        { yPercent: 100 },
        {
            yPercent: 0,
            duration: 1 * speedKoef,
            stagger: 0.1,
        },
        '0',
    );

    return tl;
}

function init() {
    Array.from(document.querySelectorAll<HTMLElement>(selector)).forEach(
        (el) => {
            const tl = splittedText(el);
            executeOnIntersection(el, () => tl.play(), { threshold: 0.6 });
        },
    );
}

const _module = { init };

export default _module;
